import  React from "react"

//COMPONENTS
import Layout from "../../components/layout"
import Seo from "@components/Seo.js"
import Breadcrumbs from "../../components/Breadcrumbs"

const DiscountsPage = () => {

    const pageData = {
        title: "Discounts",
        description: `Learn about our company discounts and loyalty discount programs.`,
        keywords: `essay discount, term paper discount, dissertation discount, discounted papers, discount for college essay, writing at discount`
    }

    const steps= [
        {
            name: 'Discounts',
            path: '/info/discounts'
        }
    ]

    return (
        <Layout>
            <Seo {...pageData} />
            <div className="info-intro">
                <div className="wrapper">
                    <div className="info-intro--content">
                        <Breadcrumbs steps={steps}/>
                        <h1 className="page-intro__title">Discounts</h1>
                    </div>                    
                </div>
            </div>
            <section className="discounts">
                <div className="wrapper">
                    <div className="row">
                        <div className="discounts-content">
                            <p>
                                In addition to the one-time discount codes that may be offered on special occasions,
                                we have a standardized automated &laquo;Loyalty Program&raquo;
                                that is of special interest to our clients because the
                                vast majority of them order more than once.
                                The program was launched back in the days (March 6th, 2009)
                                and has worked flawlessly ever since without any changes.
                            </p>

                            <p>
                                It is integrated with the ordering process
                                and works automatically. The discount rates
                                are calculated automatically for your account
                                based on the amount spent and are applied
                                instantly during the checkout process.
                                The only thing you need to remember to do
                                is to log in with your account credentials during the checkout process.
                            </p>

                            <div className="discounts-content__subsequent">
                                <h3>Discount on all subsequent orders</h3>

                                <div className="discounts-content__subsequent-process">
                                    <div className="discounts-content__subsequent-process__bar"></div>

                                    <div className="discounts-content__subsequent-process-item">
                                        <span className="discounts-content__subsequent-process-item-title">5 <i>%</i></span>
                                        <span className="discounts-content__subsequent-process-desc">More than $500</span>
                                    </div>

                                    <div className="discounts-content__subsequent-process-item">
                                        <span className="discounts-content__subsequent-process-item-title">7<i>%</i></span>
                                        <span className="discounts-content__subsequent-process-desc">From $1,000 to $2,000</span>
                                    </div>

                                    <div className="discounts-content__subsequent-process-item">
                                        <span className="discounts-content__subsequent-process-item-title">10<i>%</i></span>
                                        <span className="discounts-content__subsequent-process-desc">From $2,000 to $30,000</span>
                                    </div>
                                    <div className="discounts-content__subsequent-process-item">
                                        <span className="discounts-content__subsequent-process-item-title">15<i>%</i></span>
                                        <span className="discounts-content__subsequent-process-desc">Over $30,000</span>
                                    </div>
                                </div>
                            </div>

                            <h3>Coupon Codes</h3>

                            <p>
                                Coupon codes may be offered on special occasions.
                                If you have a coupon code sent by our company,
                                you can redeem the discount by entering the code during
                                the checkout process. Please keep in mind that
                                loyalty discounts do not apply, when a special discount is used.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default DiscountsPage
